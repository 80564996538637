<template>
  <div class="career">
    <div class="career__container v2-container">
      <div class="career__main">
        <div class="career__left">
          <h2 class="career__title title-secondary">
            профориентация
          </h2>

          <p class="career__tx text-main">
            Профориентация в&nbsp;нашей школе —&nbsp;это поиск новых
            возможностей, раскрытие своих сильных сторон, получение
            достоверной и&nbsp;актуальной информации о&nbsp;профессиях и&nbsp;мире труда
          </p>

          <nuxt-link
            to="/proforientacia"
            class="career__btn m-btn m-btn--primary"
          >
            <span class="career__btn-cont m-btn__content m-btn__content--primary">
              Узнать подробнее
            </span>
          </nuxt-link>
        </div>

        <div class="career__rigth">
          <img
            loading="lazy"
            class="career__img"
            src="/v2/school/career-guidance/img.webp"
            alt="chat"
          >

          <img
            loading="lazy"
            class="career__img career__img--mob"
            src="/v2/school/career-guidance/img-mob.webp"
            alt="chat"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolCareerGuidance',
};
</script>

<style lang="scss" scoped>
.career {
  padding-top: 10px;

  overflow: hidden;

  &__container {
    max-width: 1272px;
  }

  &__main {
    display: flex;
    gap: 10px;

    @media only screen and (max-width: $school-desktop) {
      flex-direction: column;
      align-items: center;

      gap: 30px;
    }
  }

  &__left {
    padding-top: 120px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;

      max-width: 550px;
    }

    @media only screen and (max-width: $school-desktop) {
      padding-top: 0;
    }
  }

  &__title {
    margin-bottom: 20px;

    letter-spacing: -1.35px;

    @media only screen and (max-width: $school-desktop) {
      text-align: center;
    }

    @include media-down(tablet) {
      margin-bottom: 12px;

      font-size: 30px;
      text-transform: capitalize;
    }
  }

  &__tx {
    margin-bottom: 40px;

    @include media-down(tablet) {
      margin-bottom: 32px;

      font-weight: 600;
    }
  }

  &__btn {
    width: 338px;
    display: block;

    @media only screen and (max-width: $school-desktop) {
      margin: 0 auto;
    }

    @include media-down(tablet) {
      width: 210px;
    }
  }

  &__btn-cont {
    padding: 14px 45px 16px 45px;
    white-space: nowrap;

    background: #73b0f4;

    @include media-down(tablet) {
      padding: 10px 20px 10px 20px;

      font-size: 18px;
    }
  }

  &__rigth {
    position: relative;
    right: -29px;

    @media only screen and (max-width: $school-desktop) {
      right: 50px;
    }

    @include media-down(laptop) {
      right: 10px;
    }
  }

  &__link {
    position: absolute;
    top: -20px;
    left: 0;

    @include media-down(mobile) {
      z-index: 1;
      top: -26px;
    }
  }

  &__sticker {
    width: 175.864px;

    @include media-down(mobile) {
      width: 113px;
    }
  }

  &__img {
    position: relative;

    top: -12px;
    left: 38px;
    width: 720px;

    object-fit: contain;

    pointer-events: none;

    @include media-down(tablet) {
      display: none;
    }

    &--mob {
      top: auto;
      left: auto;

      display: none;

      @include media-down(tablet) {
        display: block;

        margin-right: -20px;

        width: 101vw;
      }
    }
  }
}
</style>
